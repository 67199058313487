import React, { useState } from 'react';
import clsx from 'clsx';
import Alert from 'components/Alert';
import { t } from 'i18next';
import { Route, Switch, useLocation } from 'react-router-dom';

import { makeStyles } from '@alpha/ui-lib/theme';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlphaConnectAdvert from '../../../assets/AlphaConnectAdvert.png';
import AlphaGreenLogo from '../../../assets/AlphaGreenLogo.png';
import AuthLayout from '../../../components/Layout/Auth';
import { TSignIn } from '../../../models/auth';
import routes from '../../../routes.path';
import LangSelectorGroup from '../LangSelectorGroup';
import Login from '../Login';
import VerifyMfaHelperScreen from '../VerifyMfa/HelperScreen';
import { IAuthProps } from '..';

import InvalidSession from './InvalidSession';
import PostLogin from './PostLogin';

export interface IReturning {
  userObject: TSignIn;
}

const useStyles = makeStyles(() => ({
  leftContent: {
    display: 'flex',
    backgroundColor: 'unset',
    justifyContent: 'space-between',
    height: '100vh',
    maxWidth: '700px',
    '& .alphaLogo': {
      width: '144px',
      height: '36px',
    },
  },
  welcomeScreenLeftContent: {
    '& > .wrapper': {
      justifyContent: 'unset',
    },
  },
  alert: {
    '& .closeButton': {
      color: '#00B8A3 !important',
    },
    borderRadius: '0px',
    padding: '0px 32px 0px 32px !important',
    backgroundColor: '#252525 !important',
    marginBottom: '0px',
    '&.info': {
      '& .text': {
        color: '#FFFFFF',

      },
    },
  },
  '@media (max-width: 768px)': {
    leftContent: {
      width: '100%',
    },
  },
  container: {
  },
  alphaAdvert: {
    backgroundImage: `url(${AlphaConnectAdvert})`,
    width: '700px',
    height: 'calc(100vh - 64px)',
    minHeight: '715px',
    backgroundColor: 'black',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    borderRadius: '24px',
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundPosition: 'center',
  },
  '@media (min-width: 1920px)': {
    container: {
    },
    alphaAdvert: {
      width: '700px',
      maxHeight: 'calc(100vh - 148px)',
    },
    authySetup: {
      width: '700px',
      maxHeight: 'calc(100vh - 80px)',
    },
  },
  '@media (max-width: 1024px) and (min-width: 768px)': {
    alphaAdvert: {
      width: '100%',
      height: 'auto',
    },
    authySetup: {
      width: '100%',
      height: 'auto',
    },
  },
  '@media (max-width: 767px)': {
    alphaAdvert: {
      width: '100%',
      height: 'auto',
    },
    authySetup: {
      width: '100%',
      height: 'auto',
    },
  },
}), { name: 'Returning' });

const Returning: React.FC<IAuthProps> = ({ userObject, userInfo, onboarding }: IAuthProps) => {
  const styles = useStyles();
  const [displayAlert, setDisplayAlert] = useState(true);
  const location = useLocation();
  const handleOnClose = () => {
    setDisplayAlert(false);
  };
  const isOnboardingFlow = location?.pathname.includes('onboarding');
  const isWelcomeScreen = location?.pathname.includes('welcome');
  const showLangSelector = !(location?.pathname.includes('mfa-options') || location?.pathname.includes('welcome'));

  return (
    <div style={{ minWidth: '100%', margin: 'auto', background: '#000000' }}>
      {displayAlert && !isOnboardingFlow ? (
        <div style={{
          position: 'fixed', width: '100%', zIndex: 1000, top: 0,
        }}
        >
          <Alert
            hideIcon
            showCloseButton
            onClose={handleOnClose}
            className={styles.alert}
            variant="info"
            text={(
              <span style={{ fontWeight: 300, fontSize: '19px', lineHeight: '45.83px' }}>
                {t('login_banner')}
                <span style={{
                  color: '#00B8A3', marginLeft: '4px', fontWeight: 300, fontSize: '19px', lineHeight: '45.83px',
                }}
                >
                  {t('login_banner_sub_text')}
                </span>
              </span>
)}
          />
        </div>
      ) : null}
      <AuthLayout.Container>
        <AuthLayout.LeftContent className={clsx(styles.leftContent, { [styles.welcomeScreenLeftContent]: isWelcomeScreen })}>
          <div style={{ display: 'flex', marginTop: '85px' }}>
            <img
              className="alphaLogo"
              src={AlphaGreenLogo}
              alt="Alpha Group Logo"
            />
          </div>
          <Switch>
            <Route path={routes.invalidSession}>
              <InvalidSession />
            </Route>
            <Route path={routes.auth.login}>
              <Login onboarding={false} />
            </Route>
            <PostLogin userObject={userObject} userInfo={userInfo} onboarding={onboarding} />

          </Switch>
          {showLangSelector ? (
            <div>
              <div style={{
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: 'auto',
                alignItems: 'center',
                marginBottom: '32px',
              }}
              >

                <LangSelectorGroup />
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  <a style={{ fontWeight: 400, fontSize: '13px' }} target="_blank" href="https://www.alphagroup.com" rel="noreferrer">
                    Alpha Group
                  </a>
                  <FontAwesomeIcon icon={faExternalLink} color="#00B8A3" style={{ marginLeft: '10px' }} />
                </div>
              </div>
            </div>
          ) : <div style={{ marginBottom: '32px' }} />}

        </AuthLayout.LeftContent>
        <AuthLayout.RightContent>
          <Route path={routes.auth.totp}>
            <div className={styles.container}>
              <VerifyMfaHelperScreen userObject={userObject} />
            </div>
          </Route>
          <Route path={routes.auth.login}>
            <div className={styles.container}>
              <div className={styles.alphaAdvert} />
            </div>
          </Route>
          <Route path={routes.auth.mfaOptions}>
            <div className={styles.container}>
              <VerifyMfaHelperScreen userObject={userObject} />
            </div>
          </Route>
          <Route path={routes.auth.welcome}>
            <div className={styles.container}>
              <div className={styles.alphaAdvert} />
            </div>
          </Route>
        </AuthLayout.RightContent>
      </AuthLayout.Container>
    </div>
  );
};

export default Returning;
