import React, { useState } from 'react';
import AlphaConnectAdvert from 'assets/AlphaConnectAdvert.png';
import AlphaGreenLogo from 'assets/AlphaGreenLogo.png';
import Authy from 'assets/authyBackground.png';
import PasswordResetImg from 'assets/resetPassword.svg';
import AuthLayout from 'components/Layout/Auth';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import routes from 'routes.path';

import { makeStyles } from '@alpha/ui-lib/ui/external';

import Request from './Request';
import Success from './Success';
import Verify from './Verify';

const useStyles = makeStyles(() => ({
  leftContent: {
    display: 'flex',
    backgroundColor: 'unset',
    // alignItems: 'end',
    maxWidth: '700px',
    justifyContent: 'space-between',
    '& .alphaLogo': {
      width: '144px',
      height: '36px',
    },
  },
  '@media (max-width: 768px)': {
    leftContent: {
      width: '100%',
    },
  },
  rightContent: {
    height: '100%',
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alphaAdvert: {
    backgroundImage: `url(${AlphaConnectAdvert})`,
    width: '700px',
    height: 'calc(100vh - 64px)',
    minHeight: '715px',
    backgroundColor: 'black',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    borderRadius: '24px',
    backgroundPosition: 'center',
  },
  passwordResetImg: {
    backgroundImage: `url(${PasswordResetImg})`,
    width: '481px',
    height: '337px',
    backgroundSize: 'auto 100%',
  },
  authySetup: {
    backgroundImage: `url(${Authy})`,
    width: '700px',
    height: 'calc(100vh - 64px)',
    minHeight: '715px',
    backgroundColor: 'black',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
    borderRadius: '24px',
    maxHeight: '100%',
    alignItems: 'center',
    display: 'flex',
  },
  authyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '152px 74px 152px 74px',
    borderRadius: '16px',
    width: '552px',
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    padding: '40px 40px 40px 40px',
    minHeight: '628px',
  },
  container: {
  },
  '@media (min-width: 1920px)': {
    container: {
    },
    alphaAdvert: {
      width: '700px',
      maxHeight: 'calc(100vh - 148px)',
    },
    PasswordResetImg: {
      width: '700px',
      maxHeight: 'calc(100vh - 80px)',
    },
    authySetup: {
      width: '700px',
      maxHeight: 'calc(100vh - 80px)',
    },
  },
  '@media (max-width: 1024px) and (min-width: 768px)': {
    alphaAdvert: {
      width: '100%',
      height: 'auto',
    },
    PasswordResetImg: {
      width: '100%',
      height: 'auto',
    },
    authySetup: {
      width: '100%',
      height: 'auto',
    },
  },
  '@media (max-width: 767px)': {
    alphaAdvert: {
      width: '100%',
      height: 'auto',
    },
    authySetup: {
      width: '100%',
      height: 'auto',
    },
    PasswordResetImg: {
      width: '100%',
      height: 'auto',
    },
  },
}), { name: 'Returning' });

const PasswordReset = () => {
  const [username, setUsername] = useState<string>();
  const [email, setEmail] = useState<string>();
  const { pathname } = useLocation();
  const styles = useStyles();

  return (
    <div style={{ minWidth: '100%', margin: 'auto', background: '#000000' }}>
      <AuthLayout.Container>
        <AuthLayout.LeftContent
          className={
      styles.leftContent
        }
        >
          <div style={{ display: 'flex', marginTop: '85px' }}>
            <img
              className="alphaLogo"
              src={AlphaGreenLogo}
              alt="Alpha Group Logo"
            />
          </div>
          <Switch>
            <Route exact path={routes.auth.passwordReset.request}>
              <Request setUsername={setUsername} setEmail={setEmail} />
            </Route>
            <Route exact path={routes.auth.passwordReset.verifyMfa}>
              <Verify username={username} email={email} />
            </Route>
            <Route exact path={routes.auth.passwordReset.success}>
              <Success />
            </Route>
            <Redirect to={routes.auth.passwordReset.request} />
          </Switch>
          <div style={{ marginBottom: '32px' }} />

        </AuthLayout.LeftContent>
        <AuthLayout.RightContent>
          <Switch>
            <Route exact path={routes.auth.passwordReset.verifyMfa}>
              <div className={styles.container}>
                <div className={styles.rightContent}>
                  <div className={styles.authySetup}>
                    <div className={styles.authyContainer}>
                      <div className={styles.passwordResetImg} />
                    </div>
                  </div>
                </div>
              </div>
            </Route>
            <Route exact path={routes.auth.passwordReset.request}>
              <div className={styles.container}>
                <div className={styles.alphaAdvert} />
              </div>
            </Route>
          </Switch>
        </AuthLayout.RightContent>
      </AuthLayout.Container>
    </div>
  );
};

export default PasswordReset;
