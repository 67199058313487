import React, { useEffect, useState } from 'react';
import useSwitchAccount from 'hooks/useSwitchAccount';
import BeneficiariesService from 'services/Beneficiaries/beneficiaries.service';
import t from 'utils/translationHelper';

import { BeneficiaryDto } from '@alpha/bene-dtos';
import { TradeDto } from '@alpha/fx-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { ConfirmationModal } from '@alpha/ui-lib/ui/Modal/ConfirmationModal';

import useAuthorization from '../../../../hooks/useAuthorization';
import useLog from '../../../../hooks/useLog';
import { UserRole } from '../../../../models/user';
import DrawerBackdropLoader from '../../Loaders/DrawerBackdropLoader/DrawerBackdropLoader';

import Drawdown from './Drawdown';
import DrawdownConfirmation from './DrawdownConfirmation';
import useStyles from './index.styles';
import useCreateFirstPartyDrawdownDrawer from './useFirstPartyCreateDrawdownDrawer';

export type Section = 'Drawdown' | 'Settlement' | 'Submit';

type Props = {
  open: boolean;
  onClose: () => void;
  trade: TradeDto;
  beneficiary?: BeneficiaryDto;
};

// eslint-disable-next-line max-lines-per-function
const FirstPartyCreateDrawdownDrawer: React.FC<Props> = ({
  open,
  onClose,
  trade,
  beneficiary,
}) => {
  const styles = useStyles();

  const [insufficientFunds, setInsufficientFunds] = useState<boolean>(false);
  const { isEMoneyDisabled } = useSwitchAccount();
  const [defaultBeneficiary, setBeneficiary] = useState<BeneficiaryDto>([]);
  const [openPaymentClearWarning, setOpenPaymentClearWarning] =
    useState<boolean>(false);
  const [warningModalConfirmationAction, setWarningModalConfirmationAction] =
    useState<'BACK_TO_DRAWDOWN' | 'CLOSE_DRAWER'>('CLOSE_DRAWER');

  const { authorized: padApprover } = useAuthorization([
    [UserRole.PAD_APPROVER],
  ]);
  const { logError } = useLog();

  const {
    validateDrawdownMutations,
    validatePaymentMutations,
    requestForm,
    drawdownSubmissionForm,
    handleAmountChange,
    handleDropdownChange,
    handleSetSection,
    validatedDrawdown,
    submitDrawdownMutations,
    section,
    accountConfigQuery,
    defaultSellCurrencyAccount,
    defaultBuyCurrencyAccount,
    handleDrawdownMFA,
  } = useCreateFirstPartyDrawdownDrawer(trade, open, onClose);

  const onPaymentClearWarningConfirmation = (
    action: 'BACK_TO_DRAWDOWN' | 'CLOSE_DRAWER'
  ) => {
    if (action === 'BACK_TO_DRAWDOWN') {
      handleSetSection('Drawdown');
    }
    if (action === 'CLOSE_DRAWER') {
      onClose();
    }
    requestForm.setFieldValue('payments', []);
  };

  const onDrawerClose = (): void => {
    const shouldShowWarning = Boolean(
      requestForm.values.payments && requestForm.values.payments?.length > 0
    );
    if (shouldShowWarning) {
      setWarningModalConfirmationAction('CLOSE_DRAWER');
      setOpenPaymentClearWarning(true);
    } else {
      onClose();
    }
  };

  const getSelectedBeneficiary = async (defaultSSI?: string) => {
    if (!defaultSSI) { return; }
    try {
      const bene = await BeneficiariesService.getIndividualBeneficiary(
        defaultSSI
      );
      setBeneficiary(bene);
    } catch (error) {
      logError({ action: 'Error loading beneficiaries', error });
    }
  };

  useEffect(() => {
    getSelectedBeneficiary(trade?.defaultSSI);
  }, [trade]);

  useEffect(() => {
    checkInsufficientAmount();
  }, [requestForm.values.fixCurrencyCode, requestForm.values.amount]);

  const checkInsufficientAmount = () => {
    const amountValue = parseFloat(
      requestForm.values.amount.toString().replace(/,/g, '')
    );

    if (
      requestForm.values.fixCurrencyCode === requestForm.values.sellCurrencyCode
    ) {
      if (defaultSellCurrencyAccount) {
        setInsufficientFunds(
          defaultSellCurrencyAccount.clearedBalance < amountValue
        );
      }
    } else if (defaultSellCurrencyAccount) {
      setInsufficientFunds(
        defaultSellCurrencyAccount.clearedBalance < amountValue / trade?.rate!
      );
    }
  };

  return (
    <>
      <BaseDrawer.Drawer
        open={open}
        onClose={onDrawerClose}
        anchor='right'
        className={styles.container}
        closeIdentifier='create-first-party-drawdown-drawer'
      >
        <BaseDrawer.Header
          headerTitle={
            trade?.defaultSSI && !isEMoneyDisabled
              ? t('quick_drawdown')
              : t('first_party_drawdown')
          }
          headerSubtitle=''
        >
          <div style={{ margin: '30px 0' }}>
            <BaseDrawer.LineBreak />
          </div>
        </BaseDrawer.Header>
        <BaseDrawer.Body>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: 'calc(100vh - 213px)',
              gap: '36px',
            }}
          >
            {section === 'Drawdown' && (
              <>
                <Drawdown
                  trade={trade}
                  handleSetSection={handleSetSection}
                  drawdownForm={requestForm}
                  padAllowed={
                    (accountConfigQuery.data?.padAllowed &&
                      accountConfigQuery.data?.padEligible &&
                      padApprover) ||
                    (trade && trade.padEligible && trade.padApproved)
                  }
                  handleAmountChange={handleAmountChange}
                  handleDropdownChange={handleDropdownChange}
                  buyCurrencyAccount={defaultBuyCurrencyAccount}
                  defaultSellCurrencyAccount={defaultSellCurrencyAccount!}
                  sellCurrencyAccount={defaultSellCurrencyAccount}
                  validatedDrawdown={validatedDrawdown}
                  beneficiary={beneficiary || defaultBeneficiary}
                />

                <DrawerBackdropLoader
                  display={validateDrawdownMutations.isLoading}
                  text={t('validating_your_drawdown')}
                />
                <DrawerBackdropLoader
                  display={validatePaymentMutations.isLoading}
                  text=''
                />
              </>
            )}
            {section === 'Submit' && (
              <>
                <DrawdownConfirmation
                  trade={trade}
                  handleSetSection={handleSetSection}
                  drawdownRequestForm={requestForm.values}
                  validatedDrawdown={validatedDrawdown}
                  submissionForm={drawdownSubmissionForm}
                  handleFormSubmit={handleDrawdownMFA}
                  sellCurrencyAccount={defaultSellCurrencyAccount!}
                  buyCurrencyAccount={defaultBuyCurrencyAccount!}
                />
                <DrawerBackdropLoader
                  display={
                    drawdownSubmissionForm.isSubmitting ||
                    submitDrawdownMutations.isLoading
                  }
                  text={t('submitting_your_drawdown')}
                />
              </>
            )}
          </div>
        </BaseDrawer.Body>
      </BaseDrawer.Drawer>
      <ConfirmationModal
        variant='negative'
        title={t('are_you_sure')}
        open={openPaymentClearWarning}
        onExit={() => setOpenPaymentClearWarning(false)}
        onConfirm={() => {
          if (onPaymentClearWarningConfirmation) {
            onPaymentClearWarningConfirmation(warningModalConfirmationAction);
          }
          setOpenPaymentClearWarning(false);
        }}
        confirmButtonText={t('exit')}
        exitButtonText={t('cancel')}
        content={
          <p>
            {t('you_are_about_to_abort_the_current_drawdown_flow')}
            <br />
            {t('the_drawdown_will_not_be_saved')}
            <br />
            <br />
            <br />
          </p>
        }
      />
    </>
  );
};

export default FirstPartyCreateDrawdownDrawer;
