import React from 'react';
import clsx from 'clsx';
import { IAuthProps } from 'domain/Auth';
import maskPhoneNumber from 'utils/maskPhoneNumber';
import t from 'utils/translationHelper';

import { APInput } from '@alpha/ui-lib/ui/Input';
import { Typography } from '@alpha/ui-lib/ui/Typography/Typography';
import { faDownload, faMobile, faShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppleStore from '../../../../assets/AppleStore.png';
import AuthyIcon from '../../../../assets/authyIcon.png';
import GooglePlay from '../../../../assets/googleplay.svg';

import useStyles from './index.style';

const HelperScreen: React.FC<IAuthProps> = ({ userObject }: IAuthProps) => {
  const classes = useStyles();

  const formattedPhoneNumber = maskPhoneNumber(userObject?.challengeParam?.CODE_DELIVERY_DESTINATION);

  return (
    <div className={clsx(classes.container, classes.fullOpacity)}>
      <div className={classes.authySetup}>
        <div className={classes.authyContainer}>
          <div style={{
            height: '100%', display: 'flex', flexDirection: 'column',
          }}
          >
            <div style={{ display: 'flex' }}>
              <div>
                <img
                  src={AuthyIcon}
                  alt="Authy icon"
                  style={{ width: '34px', height: '34px', borderRadius: '5px' }}
                />
              </div>
              <div>
                <Typography style={{
                  color: '#FFFFFF', fontWeight: 600, fontSize: '24px', marginLeft: '20px',
                }}
                >
                  {t('authy_setup')}
                </Typography>
              </div>
            </div>

            <div style={{ paddingTop: '60px' }}>
              <div style={{ display: 'flex' }}>
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{
                    color: 'rgba(62, 140, 193, 1)', marginRight: '8px', height: '22px', width: '18px',
                  }}
                />
                <Typography className={classes.authyHelperTitles}>{t('step_1_download_authy')}</Typography>
              </div>
              <Typography className={classes.authyHelperSubTitles}>{t('download_authy_app')}</Typography>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={AppleStore} alt="appleStore" style={{ height: '32px', width: '110.77px', marginRight: '8px' }} />
                <img src={GooglePlay} alt="googlePlay" />
              </div>
              <div style={{ border: '1px solid rgba(255, 255, 255, 0.5)', marginTop: '40px' }} />
            </div>

            <div style={{ paddingTop: '40px' }}>
              <div style={{ display: 'flex' }}>
                <FontAwesomeIcon
                  icon={faMobile}
                  style={{
                    color: 'rgba(62, 140, 193, 1)', marginRight: '8px', height: '22px', width: '18px',
                  }}
                />
                <Typography className={classes.authyHelperTitles}>{t('step_2_register_your_device')}</Typography>
              </div>
              <Typography className={classes.authyHelperSubTitles}>{t('register_device')}</Typography>
              <APInput
                testid="password"
                id="password"
                name="password"
                variant="outlined"
                fullWidth
                value={formattedPhoneNumber}
                disabled
                className={classes.phoneNumber}
              />
              <div style={{ border: '1px solid rgba(255, 255, 255, 0.5)', marginTop: '40px' }} />
            </div>

            <div style={{ paddingTop: '40px' }}>
              <div style={{ display: 'flex' }}>
                <FontAwesomeIcon
                  icon={faShieldCheck}
                  style={{
                    color: 'rgba(62, 140, 193, 1)', marginRight: '8px', height: '22px', width: '18px',
                  }}
                />
                <Typography className={classes.authyHelperTitles}>{t('step_3_use_the_application_to_authenticate')}</Typography>
              </div>
              <Typography className={classes.authyHelperSubTitles}>{t('you_will_then_see_alpha_automatically_appear_within_the_authy_app')}</Typography>
              <div style={{ marginTop: '14px' }}>
                <Typography className={classes.authyHelperSubTitles}>{t('you_can_now_use_this_token_to_securely_login')}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HelperScreen;
