import { useEffect, useMemo, useState } from 'react';
import { FormikProps } from 'formik';
import useAlphaSnackbar from 'hooks/useAlphaSnackbar';
import useLog from 'hooks/useLog';
import moment from 'moment';
import CurrencyAccountsService from 'services/CurrencyAccounts/currencyAccounts.service';
import FXTradeService from 'services/FXTrade/fxTrade.service';
import { getCurrencySymbol } from 'utils/currency.helpers';
import t from 'utils/translationHelper';

import { Currency } from '@alpha/bene-dtos/lib/responses/Currency';
import { CurrencyAccountDto } from '@alpha/currency-accounts-dtos';
import {
  AllowedForwardsCurrenciesAndConfigurationsDto, ForwardsIndicativeRateDto,
} from '@alpha/fx-dtos';

import { IOption } from '../../../../AutocompleteDropDown/AutocompleteDropDown';
import { TForwardsFXRequestForm } from '../formData';

import ignoreSnackbar from './ignoreSnackbar';

// eslint-disable-next-line max-lines-per-function
export const useInputForwardTrade = (
  form: FormikProps<TForwardsFXRequestForm>,
  setDirectInvestment: Function,
  setSettledPhysically: Function,
) => {
  const emptyOption = { name: '', code: '' };
  const sb = useAlphaSnackbar();
  const { logError } = useLog();
  const [
    allowedSellCurrenciesOption,
    setAllowedSellCurrenciesOption,
  ] = useState<IOption[]>([]);

  const [
    allowedBuyCurrenciesOption,
    setAllowedBuyCurrenciesOption,
  ] = useState<IOption[]>([]);

  const [
    selectedSellCurrencyOption,
    setSelectedSellCurrencyOption,
  ] = useState<IOption>(emptyOption);

  const [
    selectedBuyCurrencyOption,
    setSelectedBuyCurrencyOption,
  ] = useState<IOption>(emptyOption);

  const [fixedSide, setFixedSide] = useState<'Sell' | 'Buy'>('Sell');

  const [forwardsConfigurations, setForwardsConfigurations] = useState<AllowedForwardsCurrenciesAndConfigurationsDto>();

  const [allowedBuyCurrencies, setAllowBuyCurrencies] = useState<Currency[]>([]);
  const [allowedSellCurrencies, setAllowSellCurrencies] = useState<Currency[]>([]);
  const [sellCurrencyTextFieldValue, setSellCurrencyTextFieldValue] = useState<string>('');
  const [buyCurrencyTextFieldValue, setBuyCurrencyTextFieldValue] = useState<string>('');
  const [indicativeRate, setIndicativeRate] = useState<ForwardsIndicativeRateDto>();
  const [availableForwardDates, setAvailableForwardDates] = useState<string[]>([]);
  const [availableBuyCurrencies, setAvailableBuyCurrencies] = useState<{ code: string }[]>([]);
  const [availableSellCurrencies, setAvailableSellCurrencies] = useState<{ code: string }[]>([]);
  const [currencyMap, setCurrencyMap] = useState(new Map());
  const [action, setAction] = useState<string>('');

  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [rateLoading, setRateLoading] = useState<boolean>(false);
  const [valueDatesLoading, setValueDatesLoading] = useState<boolean>(false);

  const [buyStartAdornment, setBuyStartAdornment] = useState<string>('');
  const [sellStartAdornment, setSellStartAdornment] = useState<string>('');

  const getAvailableCurriencies = async (): Promise<void> => {
    setPageLoading(true);
    try {
      const forwardsConfigurationsAndCurrencies = await FXTradeService.getForwardsConfigurations();
      setForwardsConfigurations(forwardsConfigurationsAndCurrencies);

      if (forwardsConfigurationsAndCurrencies) {
        setAllowBuyCurrencies(forwardsConfigurationsAndCurrencies?.allowedCurrencies);
        setAllowedBuyCurrenciesOption(forwardsConfigurationsAndCurrencies?.allowedCurrencies);
        setAllowSellCurrencies(forwardsConfigurationsAndCurrencies?.allowedCurrencies);
        setAllowedSellCurrenciesOption(forwardsConfigurationsAndCurrencies?.allowedCurrencies);
      }

      setPageLoading(false);
    } catch (error) {
      sb.trigger(error?.message || 'Failed to load available currencies.', 'error');
      logError({ action: 'Error loading available currencies', error });
    }
  };

  const populateCurrencyPairs = () => {
    const map = new Map();

    forwardsConfigurations?.forwardsConfigurations.forEach((config) => {
      const { currencyOne, currencyTwo } = config;
      if (!map.has(currencyOne)) {
        map.set(currencyOne, []);
      }
      map.get(currencyOne).push({ code: currencyTwo });

      if (!map.has(currencyTwo)) {
        map.set(currencyTwo, []);
      }
      map.get(currencyTwo).push({ code: currencyOne });
    });

    setCurrencyMap(map);
  };

  useEffect(() => {
    populateCurrencyPairs();
  }, [forwardsConfigurations]);

  const getPairsForCurrency = (currency: string) => currencyMap?.get(currency) || [];

  const manualResetForm = () => {
    form.setFieldValue('sellAmount', 0);
    form.setFieldValue('buyAmount', 0);
    form.setFieldValue('valueDate', null);
    form.setFieldValue('fixedAmount', 0);
    form.setFieldValue('defaultSellCurrencyAccount', {});
    form.setFieldValue('defaultBuyCurrencyAccount', {});
    form.setFieldValue('selectedSellCurrencyAccount', {});
    form.setFieldValue('selectedBuyCurrencyAccount', {});
    form.setFieldValue('sellCurrencyCode', '');
    form.setFieldValue('buyCurrencyCode', '');
    setSelectedBuyCurrencyOption({ name: '', code: '' });
    setSelectedSellCurrencyOption({ name: '', code: '' });
    setSellCurrencyTextFieldValue('');
    setBuyCurrencyTextFieldValue('');
    setIndicativeRate({
      valueDate: '',
      rate: 0,
      initialMarginPercentage: 0,
      initialMarginDueDate: '',
    });
    form.setFieldTouched('sellAmount', false, false);
    form.setFieldTouched('buyAmount', false, false);
    form.setFieldTouched('sellCurrencyCode', false, false);
    form.setFieldTouched('buyCurrencyCode', false, false);
  };

  useEffect(() => {
    // RESPONSIBLE FOR SETTING THE AMOUNT, SIDE AND CURRENCY ACCOUNT
    setBuyStartAdornment(getCurrencySymbol(form.values.buyCurrencyCode) || '');

    form.setFieldValue('sellAmount', form.values.sellAmount, false);
    form.setFieldValue('buyAmount', form.values.buyAmount, false);
    const buySideFixed = form.values.fixedCurrencyCode === form.values.buyCurrencyCode;
    form.setFieldTouched('sellAmount', !buySideFixed);
    form.setFieldTouched('buyAmount', buySideFixed);

    if (fixedSide !== 'Buy') {
      // Clear buy amount
      form.setFieldValue('buyAmount', 0, false);
      form.setFieldTouched('buyAmount', false);
    }
    if (fixedSide === 'Buy') {
      form.setFieldValue('fixedCurrencyCode', form.values.buyCurrencyCode);
      const buyAmount = parseFloat(form.values.buyAmount.toString().replace(/,/g, ''));
      form.setFieldValue('fixedAmount', buyAmount);
      form.setFieldTouched('buyAmount', true);
    }

    (async () => {
      if (form.values.buyCurrencyCode) {
        const defaultBuyCurrencyAccount = await getDefaultCurrencyAccount(
          form.values.buyCurrencyCode,
        );
        form.setFieldValue('defaultBuyCurrencyAccount', defaultBuyCurrencyAccount);
      } else {
        form.setFieldValue('defaultBuyCurrencyAccount', undefined);
      }
    })();

    // RESPONSIBLE FOR CHANGING
    const availablePairs = getPairsForCurrency(form.values.buyCurrencyCode);
    setAvailableSellCurrencies(availablePairs);
  }, [form.values.buyCurrencyCode]);

  useEffect(() => {
    // FIRST PART
    setSellStartAdornment(getCurrencySymbol(form.values.sellCurrencyCode) || '');
    form.setFieldValue('sellAmount', form.values.sellAmount, false);
    form.setFieldValue('buyAmount', form.values.buyAmount, false);
    const buySideFixed = form.values.fixedCurrencyCode === form.values.buyCurrencyCode;
    form.setFieldTouched('sellAmount', !buySideFixed);
    form.setFieldTouched('buyAmount', buySideFixed);
    if (fixedSide !== 'Sell') {
      // Clear sell amounts
      form.setFieldValue('sellAmount', 0, false);
      form.setFieldTouched('sellAmount', false);
    }

    if (fixedSide === 'Sell') {
      form.setFieldValue('fixedCurrencyCode', form.values.sellCurrencyCode);
      const sellAmount = parseFloat(form.values.sellAmount.toString().replace(/,/g, ''));
      form.setFieldValue('fixedAmount', sellAmount);
    }
    (async () => {
      if (form.values.sellCurrencyCode) {
        const defaultSellCurrencyAccount = await getDefaultCurrencyAccount(
          form.values.sellCurrencyCode,
        );
        form.setFieldValue('defaultSellCurrencyAccount', defaultSellCurrencyAccount);
      } else {
        form.setFieldValue('defaultSellCurrencyAccount', undefined);
      }
    })();

    // SECOND PART
    const availablePairs = getPairsForCurrency(form.values.sellCurrencyCode);
    setAvailableBuyCurrencies(availablePairs);
  }, [form.values.sellCurrencyCode]);

  const updateCounterSideAmount = async (
    amountFixedSide: 'Buy' | 'Sell',
    amount: number,
    rate: number,
    buyCcy: string,
    sellCcy: string,
  ): Promise<void> => {
    if (!(buyCcy && sellCcy)) {
      return;
    }

    const opposingFieldAmount = FXTradeService.calculateTradeAmount(
      amountFixedSide,
      amount,
      rate || 0,
    );

    if (amountFixedSide === 'Buy') {
      (async () => {
        await form.setFieldValue('sellAmount', opposingFieldAmount.toFixed(2), true);
        await form.setFieldTouched('sellAmount', true, false);
        form.validateField('sellAmount');
      })();
    } else {
      (async () => {
        await form.setFieldValue('buyAmount', opposingFieldAmount.toFixed(2), true);
        await form.setFieldTouched('buyAmount', true, false);
        form.validateField('buyAmount');
        await form.setFieldTouched('sellAmount', true, true);
      })();
    }
  };

  const getIndicativeRate = async (
    sellCurrency: string,
    buyCurrency: string,
    fixedSideIndicative: string,
    amount: number,
    valueDate: string,
    debitCurrencyAccount: CurrencyAccountDto,
    creditCurrencyAccount: CurrencyAccountDto,
  ): Promise<void> => {
    setRateLoading(true);
    const debitCurrencyAccountId = debitCurrencyAccount?.id;
    const creditCurrencyAccountId = creditCurrencyAccount?.id;
    try {
      if (availableForwardDates?.includes(valueDate) && buyCurrency === creditCurrencyAccount.currency && sellCurrency === debitCurrencyAccount.currency) {
        const indicativeRateResponse = await FXTradeService.postForwardsIndicativeRateRequest({
          sellCurrency,
          buyCurrency,
          fixedSide: fixedSideIndicative,
          amount,
          valueDate,
          debitCurrencyAccount: debitCurrencyAccountId,
          creditCurrencyAccount: creditCurrencyAccountId,
        });
        setIndicativeRate(indicativeRateResponse);
        form.setFieldValue('indicativeRate', indicativeRateResponse.rate);
        setRateLoading(false);
      }
      setRateLoading(false);
    } catch (e: any) {
      const ignoreShowingSnackbar = ignoreSnackbar(e?.response?.data?.error);
      setIndicativeRate({
        rate: 0.0, valueDate: '', initialMarginPercentage: 0, initialMarginDueDate: '',
      });
      form.setFieldValue('indicativeRate', 0);
      setRateLoading(false);
      const errorMessage = (e.response?.data?.errors?.length
        && (t(e.response?.data?.errors[0]))) || t(e.response?.data?.error) || e.message || 'Unable to load indicative rate';
      if (!ignoreShowingSnackbar) { sb.trigger(errorMessage, 'error'); }
      logError({ action: 'Error getting indicative rate', error: e });
    }
  };

  useEffect(() => {
    if (indicativeRate && indicativeRate?.initialMarginPercentage > 0) {
      form.setFieldValue('initialMarginDueDate', indicativeRate.initialMarginDueDate);
      form.setFieldValue('initialMarginPercentage', indicativeRate.initialMarginPercentage);
    }
  }, [indicativeRate]);

  const getAvailableForwardDates = async (
    sellCurrencyCode: string,
    buyCurrencyCode: string,
    amount: number,
  ): Promise<void> => {
    form.validateForm();
    setValueDatesLoading(true);
    try {
      if (amount > 0
        && form.errors.buyAmount === undefined
        && form.errors.sellAmount === undefined
        && fixedSide
        && sellCurrencyCode
        && buyCurrencyCode) {
        const availableForwardsDatesResponse = await FXTradeService.postAvailableForwardDates(
          sellCurrencyCode,
          buyCurrencyCode,
          amount,
          fixedSide,
        );
        const todayDate = moment().format('YYYY-MM-DD');
        const filteredDates = availableForwardsDatesResponse.availableDates.filter((date) => moment(date).isSameOrAfter(todayDate));
        setAvailableForwardDates(filteredDates);
        setValueDatesLoading(false);
      } else {
        setValueDatesLoading(false);
      }
    } catch (e: any) {
      setAvailableForwardDates([]);
      setValueDatesLoading(false);
    }
  };

  const getDefaultCurrencyAccount = async (
    currencyCode: string,
  ) => {
    const currencyAccounts = await CurrencyAccountsService.getCurrencyStats(
      currencyCode,
    );

    const defaultCA = currencyAccounts.currencyAccounts?.find(
      (ca) => ca.default,
    );

    if (defaultCA) {
      return defaultCA;
    }

    return currencyAccounts.currencyAccounts[0];
  };

  const insufficientFunds = (): boolean => {
    if (form.values?.selectedSellCurrencyAccount) {
      return +form.values.sellAmount.toString().replace(/,/g, '') > form.values?.selectedSellCurrencyAccount.clearedBalance;
    }
    return false;
  };

  const handleClearAll = () => {
    manualResetForm();
    setDirectInvestment(undefined);
    setSettledPhysically(undefined);
    setSelectedBuyCurrencyOption(emptyOption);
    setSelectedSellCurrencyOption(emptyOption);
  };

  useEffect(() => {
    getAvailableCurriencies();
  }, []);

  useEffect(() => {
    const preservedSellCurrencyOption = allowedSellCurrenciesOption.find(
      (i) => i.code === form.values.sellCurrencyCode,
    );
    if (preservedSellCurrencyOption) { setSelectedSellCurrencyOption(preservedSellCurrencyOption); }
  }, [allowedSellCurrenciesOption]);

  useEffect(() => {
    const preservedBuyCurrencyOption = allowedBuyCurrenciesOption.find(
      (i) => i.code === form.values.buyCurrencyCode,
    );
    if (preservedBuyCurrencyOption) { setSelectedBuyCurrencyOption(preservedBuyCurrencyOption); }
  }, [allowedBuyCurrenciesOption]);

  useEffect(() => {
    if (form.values.sellCurrencyCode !== ''
      && form.values.buyCurrencyCode !== ''
      && form.values.sellCurrencyCode !== form.values.buyCurrencyCode
      && form.values.fixedAmount > 0) {
      getAvailableForwardDates(form.values.sellCurrencyCode, form.values.buyCurrencyCode, form.values.fixedAmount);
    }
  }, [form.values.sellCurrencyCode, form.values.buyCurrencyCode, form.values.fixedAmount, fixedSide]);

  useEffect(() => {
    if (form.values.fixedAmount > 0
      && form.values.valueDate !== '' && form.values.valueDate !== null
      && form.values.sellCurrencyCode !== ''
      && form.values.buyCurrencyCode !== ''
      && form.values.selectedSellCurrencyAccount
      && form.values.selectedBuyCurrencyAccount
    ) {
      if (fixedSide !== undefined) {
        getIndicativeRate(form.values.sellCurrencyCode,
          form.values.buyCurrencyCode,
          fixedSide,
          form.values.fixedAmount,
          form.values.valueDate,
          form.values.selectedSellCurrencyAccount,
          form.values.selectedBuyCurrencyAccount);
      }
    }
  }, [form.values.sellCurrencyCode, form.values.buyCurrencyCode, form.values.fixedAmount, form.values.valueDate, availableForwardDates]);

  useEffect(() => {
    if (indicativeRate?.valueDate
        && availableForwardDates?.length
        && form.values.buyCurrencyCode !== ''
        && form.values.sellCurrencyCode !== ''
        && form.values.buyAmount !== 0
        && form.values.sellAmount !== 0) {
      const earliestDate = moment(availableForwardDates[0]).isSameOrBefore(indicativeRate.valueDate)
        ? indicativeRate?.valueDate
        : availableForwardDates[0];

      form.setFieldValue('valueDate', earliestDate);
    }

    if (!availableForwardDates || availableForwardDates?.length === 0 || !form.values.buyCurrencyCode
      || !form.values.sellCurrencyCode) {
      form.setFieldValue('valueDate', null);
    }
  }, [indicativeRate?.valueDate, availableForwardDates, form.values.buyCurrencyCode, form.values.sellCurrencyCode]);

  const swapCurrencies = async () => {
    const newSellCcyCode = form.values.buyCurrencyCode;
    const newBuyCcyCode = form.values.sellCurrencyCode;

    const newSellCurrencyAccount = form.values.selectedBuyCurrencyAccount;
    const newBuyCurrencyAccount = form.values.selectedSellCurrencyAccount;

    const newSellCcy = allowedSellCurrenciesOption.find((item) => (item.code === newSellCcyCode));
    const newBuyCcy = allowedBuyCurrenciesOption.find((item) => (item.code === newBuyCcyCode));

    // If selected, a buy currency can be swapped only if in the sell-allowed list , and vice versa
    const canSwap = (!newSellCcyCode || newSellCcy) && (!newBuyCcyCode || newBuyCcy);
    setFixedSide(fixedSide === 'Sell' ? 'Buy' : 'Sell');

    if (!canSwap) {
      sb.trigger('Note: could not swap these two currencies.', 'info');
      return;
    }

    setIndicativeRate({
      valueDate: '',
      rate: 0,
      initialMarginPercentage: 0,
      initialMarginDueDate: '',
    });

    setSelectedBuyCurrencyOption(newBuyCcy ?? { name: '', code: '' });
    setSelectedSellCurrencyOption(newSellCcy ?? { name: '', code: '' });

    form.setFieldValue('buyCurrencyCode', newBuyCcyCode, true);
    form.setFieldValue('sellCurrencyCode', newSellCcyCode, true);

    form.setFieldValue('buyAmount', form.values.sellAmount, true);
    form.setFieldValue('sellAmount', form.values.buyAmount, true);

    form.setFieldValue('selectedBuyCurrencyAccount', newBuyCurrencyAccount);
    form.setFieldValue('selectedSellCurrencyAccount', newSellCurrencyAccount);

    setAction('swapCurrencies');
    form.setTouched({
      buyAmount: true,
      sellAmount: true,
      fixedAmount: true,
      buyCurrencyCode: true,
      sellCurrencyCode: true,
    });
    form.validateForm();
  };

  return {
    allowedSellCurrenciesOption,
    allowedBuyCurrenciesOption,
    selectedSellCurrencyOption,
    setSelectedSellCurrencyOption,
    selectedBuyCurrencyOption,
    setSelectedBuyCurrencyOption,
    sellCurrencyTextFieldValue,
    setSellCurrencyTextFieldValue,
    buyCurrencyTextFieldValue,
    setBuyCurrencyTextFieldValue,
    fixedSide,
    setFixedSide,
    indicativeRate,
    setIndicativeRate,
    pageLoading,
    setPageLoading,
    rateLoading,
    updateCounterSideAmount,
    buyStartAdornment,
    setBuyStartAdornment,
    sellStartAdornment,
    setSellStartAdornment,
    insufficientFunds,
    availableForwardsDates: availableForwardDates,
    valueDatesLoading,
    swapCurrencies,
    handleClearAll,
    availableBuyCurrencies,
    availableSellCurrencies,
    manualResetForm,
  };
};

export default useInputForwardTrade;
