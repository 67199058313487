import React, {
  useEffect, useRef, useState,
} from 'react';
import Alert from 'components/Alert';
import FormTitle from 'components/Molecules/Auth/FormTitle';
import useLogin from 'hooks/useLogin';
import useQueryString from 'hooks/useQueryString';
import { NavLink, useLocation } from 'react-router-dom';
import routes from 'routes.path';
import { Userpilot } from 'userpilot';
import t from 'utils/translationHelper';

import { ActionButton } from '@alpha/ui-lib/ui/button';
import { APInput } from '@alpha/ui-lib/ui/Input';
import { Typography } from '@alpha/ui-lib/ui/Typography';
import {
  faExternalLink, faEye, faEyeSlash,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useFormStyles from '../form.styles';
import LangSelectorGroup from '../LangSelectorGroup';
import { useOnboardingContext } from '../Onboarding';

interface IProps {
  onboarding?: boolean;
}

const Login: React.FC<IProps> = ({ onboarding }: IProps) => {
  const location = useLocation();
  const onboardingFlow = location.pathname.includes('onboarding');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const mounted = useRef(false);

  const [error, setError] = useState(false);
  const formClasses = useFormStyles({ isFocused, error });
  const { formik, loading } = useLogin(onboarding, useQueryString('fromAlphaConnect', '')[0]);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { handleSetLoaderPercentage } = useOnboardingContext();
  const onboardingDescription = t('to_get_started_please_enter_your_username_and_temporary_password');
  const loginDescription = t('Enter_your_email_and_password_below_to_sign_in');

  useEffect(() => {
    if (handleSetLoaderPercentage) {
      handleSetLoaderPercentage(25);
    }
    Userpilot.clean();
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    if (inputRef2.current) {
      inputRef2.current.focus();
      const { length } = inputRef2.current.value;
      inputRef2.current.setSelectionRange(length, length);
    }
  }, [showPassword]);

  useEffect(() => {
    if (formik.errors.password) {
      setError(true);
    }
  }, [formik.errors.password]);

  return (
    <div className={formClasses.wrapperDiv}>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
        <FormTitle
          marginTop={onboardingFlow ? '0px' : '0px'}
          title={onboardingFlow ? t('verify_your_email') : t('sign_in')}
          description={onboardingFlow ? onboardingDescription : loginDescription}
        />
        <form onSubmit={formik.handleSubmit}>
          <div className={formClasses.section}>
            <Typography className={formClasses.label}>{t('username')}</Typography>
            <APInput
              inputRef={inputRef1}
              autoComplete="off"
              autoFocus
              testid="username"
              id="username"
              name="username"
              placeholder={t('enter_your_username')}
              fullWidth
              variant="outlined"
              type="text"
              className={formClasses.inputFields}
              inputProps={{
                autoComplete: 'off',
                form: {
                  autoComplete: 'new-off',
                },

              }}
              onChange={formik.handleChange}
              value={formik.values.username}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </div>
          <div className={formClasses.section} data-dd-privacy="hidden">
            <Typography className={formClasses.label}>
              {onboardingFlow ? t('temporary_password') : t('Password')}
            </Typography>
            <APInput
              autoComplete="off"
              className={formClasses.passwordInput}
              InputProps={{
                onFocus: (() => setIsFocused(true)),
                onBlur: (() => setIsFocused(false)),
                endAdornment: (
                  <span
                    onClick={handleClickShowPassword}
                    className={formClasses.passwordIcon}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className={formClasses.eyeIcon} />
                  </span>

                ),
              }}
              testid="password"
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder={onboardingFlow ? t('enter_your_temporary_password') : t('enter_your_password')}
              variant="outlined"
              fullWidth
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              inputRef={inputRef2}
            />

          </div>
          <ActionButton
            size="large"
            type="submit"
            testId="submit-login"
            loading={loading}
            disabled={loading || !formik.isValid}
            fullWidth
            style={{ marginTop: '24px' }}
          >
            {onboardingFlow ? t('continue') : t('log_in')}
          </ActionButton>
          {!onboardingFlow && (
            <Typography className={formClasses.formBacklink}>
              <NavLink to={routes.auth.passwordReset.base}>{t('forgot_your_password')}</NavLink>
            </Typography>
          )}
        </form>
      </div>

    </div>

  );
};

export default Login;
