import clsx from 'clsx';
import CreateBeneficiary from 'domain/Beneficiaries/CreateBeneficiary';
import useSwitchAccount from 'hooks/useSwitchAccount';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import timer from 'utils/timer';
import t from 'utils/translationHelper';

import { Approver } from '@alpha/auth-dtos';
import { BeneficiaryDto } from '@alpha/bene-dtos';
import { TradeDto } from '@alpha/fx-dtos';
import { PaymentDto, PaymentOrigin } from '@alpha/payments-dtos';
import { BaseDrawer } from '@alpha/ui-lib/ui/Drawer/APBaseDrawer';
import { Typography } from '@alpha/ui-lib/ui/external';
import { Loader } from '@alpha/ui-lib/ui/Loader';
import { ConfirmationModal } from '@alpha/ui-lib/ui/Modal/ConfirmationModal';

import useAlphaSnackbar from '../../../../hooks/useAlphaSnackbar';
import useAuthorization from '../../../../hooks/useAuthorization';
import useForm from '../../../../hooks/useForm';
import useLog from '../../../../hooks/useLog';
import { UserRole } from '../../../../models/user';
import FXTradeService from '../../../../services/FXTrade/fxTrade.service';
import PaymentsService from '../../../../services/Payments/payments.service';
import StatementsService from '../../../../services/Statements/statements.service';
import { TStore } from '../../../../store';
import { initiatePayment } from '../../../../store/authy/actions';
import { TAuthyState } from '../../../../store/authy/reducer';
import isFxMarketClosed from '../../../../utils/fxTrades/isFxMarketClosed';
import Beneficiaries from '../Beneficiaries';
import SendPayments from '../SendPayments';

import validation, { FormikPaymentType, FormikType, initialValues } from './formData';
import Funding from './Funding';
import useStyles from './index.styles';
import useCreatePaymentDrawer from './useCreatePaymentDrawer';

export type Section = 'Funding' | 'Beneficiaries' | 'Send';

export type FundingOption = 'Account' | 'Spot';

export type TSpotOpenParam = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTradeDraft?: React.Dispatch<React.SetStateAction<TradeDto | undefined>>;
}

type Props = {
  open: boolean,
  onClose: () => void,
  currencyAccountId?: string,
  paymentDraft?: PaymentDto,
  triggerSpotOpen?: TSpotOpenParam,
}

const mapSectionToPercentage = (section: Section): '5%' | '50%' | '100%' => {
  switch (section) {
    case 'Beneficiaries':
      return '50%';
    case 'Send':
      return '100%';
    default:
    case 'Funding':
      return '5%';
  }
};

// eslint-disable-next-line max-lines-per-function
const CreatePaymentDrawer: React.FC<Props> = ({
  open,
  onClose,
  currencyAccountId,
  paymentDraft,
  triggerSpotOpen,
}) => {
  const [section, setSection] = useState<Section>('Funding');
  const [sendEmailNotification, setSendEmailNotification] = useState<boolean>(true);
  const authyState = useSelector<TStore, TAuthyState>((state) => state.authy);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [openPaymentClearWarning,
    setOpenPaymentClearWarning] = useState<boolean>(false);
  const [fundingMethod, setFundingMethod] = useState<FundingOption>('Account');
  const authy = useSelector<TStore, TAuthyState>((store) => store.authy);
  const { authorized } = useAuthorization([[UserRole.PAYMENTS_APPROVER_OWN]]);
  const { authorized: authorizedSpot } = useAuthorization(
    [[UserRole.SPOT, UserRole.SPOT_INPUTTER]],
  );
  const { authorized: authorizedForBookingSpot } = useAuthorization([[UserRole.SPOT]]);
  const dispatch = useDispatch();
  const { isEMoneyDisabled } = useSwitchAccount();
  const [createBeneficiaryOpen, setCreateBeneficiaryOpen] = useState<boolean>(false);
  const [beneToUpdate, setBeneToUpdate] = useState<BeneficiaryDto>();
  const styles = useStyles();
  const { logError, logEvent } = useLog();
  const sb = useAlphaSnackbar();
  const [refetchBene, setRefetchBene] = useState<number>(0);
  const formik = useForm(initialValues, validation, (values: FormikType) => {
    if (!values.paymentCurrencyAccount || !values.payments?.length) throw Error('Invalid Payment');
    dispatch(initiatePayment({
      type: 'PAYMENTS',
      approverOwn: authorized,
      paymentIds: values.payments!.map((p: FormikPaymentType) => p.id as string),
    }));
  });

  const { data } = useQuery('getCurrencyAccounts', StatementsService.getStatementAccounts);
  useEffect(() => {
    if (!open) {
      formik.resetForm();
      setPageLoading(false);
      setCurrentBuyCurrencyOption({ name: '', code: '' });
      setCurrentSellCurrencyOption({ name: '', code: '' });
      setSection('Funding');
      setFundingMethod('Account');
    }
    if (paymentDraft) {
      setSection('Beneficiaries');
      formik.setFieldValue('tradeDraftId', paymentDraft.tradeId);
      formik.setFieldValue('tradeId', paymentDraft.tradeId);
      formik.setFieldValue('paymentOrigin', paymentDraft.tradeId ? PaymentOrigin.SPOT_TRADE : PaymentOrigin.STANDALONE);
      if (currencyAccountId && data) {
        formik.setFieldValue('paymentCurrencyAccount', data?.currencyAccounts.find((ca) => ca.id === currencyAccountId));
        formik.setFieldValue('sellCurrencyAccount', data?.currencyAccounts.find((ca) => ca.id === paymentDraft.fundingAccountId));
      } else {
        formik.resetForm();
        setSection('Funding');
      }
    }
  }, [open]);

  useEffect(() => {
    let paymentOrigin = PaymentOrigin.STANDALONE;
    if (fundingMethod === 'Spot') {
      paymentOrigin = PaymentOrigin.DRAFT_SPOT_TRADE;
    } else if (formik.values.tradeId) {
      paymentOrigin = PaymentOrigin.SPOT_TRADE;
    } else {
      paymentOrigin = PaymentOrigin.STANDALONE;
    }

    formik.setFieldValue('paymentOrigin', paymentOrigin);
  }, [fundingMethod]);

  const openSpotTradeDrawer = async (tradeId?: string,
    callback?: Function,
    retry = 3) => {
    if (triggerSpotOpen && tradeId) {
      if (retry === 0) {
        sb.trigger(t('failed_to_retrieve_trade_draft'), 'error');
        logError({ action: 'Error loading trade draft.' });
        return;
      }
      try {
        const submittedTrade = await FXTradeService.getTradeData(tradeId);
        if (submittedTrade && triggerSpotOpen.setTradeDraft) {
          triggerSpotOpen.setTradeDraft(submittedTrade);
          triggerSpotOpen.setOpen(true);
          if (callback) callback();
        } else {
          await timer(1000);
          openSpotTradeDrawer(tradeId, callback, retry - 1);
        }
      } catch (error) {
        sb.trigger(error?.message || t('there_is_an_error_loading_trade_information'), 'error');
        logError({ action: 'Error loading trade information', error });
      }
    }
  };

  interface IPaymentExtraInfo {
    submitResult: ISubmitResult;
  }
  interface ISubmitResult {
    tradeSubmissionSuccess: boolean;
    tradeId: string;
    tradeSubmissionFriendlyErrorMessage: string;
  }

  useEffect(() => {
    if (authy.status === 'SUCCESS' && authy.type?.type === 'PAYMENTS') {
      setPageLoading(true);

      if (fundingMethod === 'Account' || (fundingMethod === 'Spot' && !authorizedForBookingSpot)) {
        onClose();
        return;
      }

      const paymentExtraInfo = ((authy.extraInfo) as IPaymentExtraInfo | undefined);
      if (typeof paymentExtraInfo === 'object' && paymentExtraInfo && 'submitResult' in paymentExtraInfo) {
        const { submitResult } = paymentExtraInfo;

        if (fundingMethod === 'Spot' && authorizedForBookingSpot && submitResult && submitResult.tradeSubmissionSuccess) {
          if (!isFxMarketClosed()) {
            sb.trigger(t('linking_payments_to_a_spot_trade'), 'success');
            logEvent({ action: 'Linking payment to spot' });
            openSpotTradeDrawer(submitResult?.tradeId, onClose, 3);
          } else {
            sb.trigger(t('fx_execution_is_not_available_at_this_time'), 'info');
            logEvent({ action: 'FX execution out of hour' });
            onClose();
          }
        } else {
          sb.trigger(submitResult.tradeSubmissionFriendlyErrorMessage || t('there_is_an_error_submitting_your_trade'), 'error');
          logEvent({ action: 'Error submitting trade', detail: { name: submitResult.tradeSubmissionFriendlyErrorMessage } });
          onClose();
        }
      } else {
        onClose();
      }
    }
  }, [authy.status]);

  const {
    allowedBuyCurrencies,
    setAllowBuyCurrencies,
    allowedSellCurrencies,
    setAllowSellCurrencies,
    currentSellCurrencyOption,
    setCurrentSellCurrencyOption,
    currentBuyCurrencyOption,
    setCurrentBuyCurrencyOption,
    allowedBuyCurrenciesOption,
    setAllowedBuyCurrenciesOption,
    allowedSellCurrenciesOption,
    setAllowedSellCurrenciesOption,
    getAvailableCurriencies,
  } = useCreatePaymentDrawer(formik);

  useEffect(() => {
    if (fundingMethod === 'Spot') {
      const matchedBuyCurrencyOption = allowedBuyCurrenciesOption.find(
        (item) => item.code === formik.values.paymentCurrencyCode,
      );
      if (matchedBuyCurrencyOption) {
        setCurrentBuyCurrencyOption(
          matchedBuyCurrencyOption,
        );
      }
    }
  }, [fundingMethod, formik.values.paymentCurrencyCode]);

  const onDrawerClose = (): void => {
    const shouldShowWarning = Boolean(formik.values.payments
      && (formik.values.payments?.length > 0) && fundingMethod === 'Spot');

    if (shouldShowWarning) {
      setOpenPaymentClearWarning(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (open && authorizedSpot) {
      getAvailableCurriencies();
    }
  }, [open, authorizedSpot]);

  useEffect(() => {
    setSendEmailNotification(!authorized);
  }, [authorized]);

  useEffect(() => {
    if (
      authyState.type?.type === 'PAYMENTS'
      && authyState.status === 'SUCCESS'
      && !formik.isSubmitting
      && sendEmailNotification
    ) {
      onSubmissionSuccess();
    }
  }, [authyState.status, authyState.type]);

  const onSubmissionSuccess = async () => {
    if (formik.values.payments?.length) {
      try {
        const approvers: Approver[] = await PaymentsService.getApprovers(
          formik.values.payments[0].id || '',
        );
        const approverIds: string[] = approvers.flatMap((approver: Approver) => (
          approver.eligible ? approver.userId : []));
        PaymentsService.postGenericPaymentApproverEmail({ approverIds });
      } catch (error) {
        sb.trigger(t('there_was_an_error_sending_your_email_notification'), 'error');
        logError({ action: 'Error sending your email notification' });
      }
    }
  };

  const handleSetSection = (_section: Section) => setSection(_section);
  const handleOpenCreateBeneDrawer = () => setCreateBeneficiaryOpen(true);

  return (
    <>
      <BaseDrawer.Drawer
        open={open}
        onClose={onDrawerClose}
        anchor="right"
        className={`${styles.container} PaymentDrawer`}
        closeIdentifier='create-payment-drawer'
      >
        <BaseDrawer.Header headerTitle={t('new_payment')} headerSubtitle="">
          <div style={{ margin: '30px 0' }}>
            <div style={{ position: 'relative', marginBottom: '30px' }} className="stepper">
              <div style={{
                marginLeft: mapSectionToPercentage(section), width: '15px', height: '15px', borderRadius: '50%', backgroundColor: '#0C8375', position: 'absolute', zIndex: '1', top: '-5px', left: '-2px',
              }}
              />
              <div style={{
                backgroundColor: '#EFEFEF', height: '5px', borderRadius: '5px', width: '100%', position: 'absolute',
              }}
              />
              <div style={{
                backgroundColor: '#0C8375', height: '5px', borderRadius: '5px', width: mapSectionToPercentage(section), position: 'absolute',
              }}
              />
              <div style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '16px',
              }}
              >
                <Typography variant="subtitle1" className={clsx(section === 'Funding' && 'active')}>{t('funding')}</Typography>
                <Typography variant="subtitle1" className={clsx(section === 'Beneficiaries' && 'active')}>{t('payments')}</Typography>
                <Typography variant="subtitle1" className={clsx(section === 'Send' && 'active')}>{t('send')}</Typography>
              </div>
            </div>
            <BaseDrawer.LineBreak />
          </div>
        </BaseDrawer.Header>
        <BaseDrawer.Body>
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 'calc(100vh - 293px)',
          }}
          >
            {pageLoading && (<Loader testId="loader" size="50px" style={{ marginTop: '40%' }} />)}
            {!pageLoading
              && (
                <>
                  {
                    section === 'Funding' && (
                      <Funding
                        {...formik}
                        handleSetSection={handleSetSection}
                        currencyAccountId={currencyAccountId}
                        currentSellCurrencyOption={currentSellCurrencyOption}
                        currentPaymentCurrencyOption={currentBuyCurrencyOption}
                        fundingMethod={fundingMethod}
                        setFundingMethod={setFundingMethod}
                        allowedSellCurrencies={allowedSellCurrencies}
                        allowedPaymentCurrencies={allowedBuyCurrencies}
                        allowedSellCurrenciesOption={allowedSellCurrenciesOption}
                        allowedPaymentCurrenciesOption={allowedBuyCurrenciesOption}
                        setCurrentSellCurrencyOption={setCurrentSellCurrencyOption}
                        setCurrentPaymentCurrencyOption={setCurrentBuyCurrencyOption}
                      />
                    )
                  }
                  {
                    section === 'Beneficiaries' && formik.values.paymentCurrencyAccount && (
                      <Beneficiaries
                        {...formik}
                        handleSetSection={handleSetSection}
                        paymentDraft={paymentDraft}
                        fundingMethod={fundingMethod}
                        tradeId={formik.values.tradeId}
                        tradeDraftId={formik.values.tradeDraftId}
                        onDrawerClose={onClose}
                        setCreateBeneficiaryOpen={handleOpenCreateBeneDrawer}
                        setBeneToUpdate={setBeneToUpdate}
                        refetchBene={refetchBene}
                      />
                    )
                  }
                  {
                    section === 'Send' && formik.values.paymentCurrencyAccount && formik.values.payments && (
                      <SendPayments
                        setFieldValue={formik.setFieldValue}
                        values={formik.values}
                        handleFormSubmit={formik.handleSubmit}
                        handleSetSection={handleSetSection}
                        sendEmailNotification={sendEmailNotification}
                        setSendEmailNotification={setSendEmailNotification}
                        fundingMethod={fundingMethod}
                      />
                    )
                  }
                </>
              )}
          </div>

        </BaseDrawer.Body>
        <ConfirmationModal
          variant="negative"
          title={t('are_you_sure')}
          open={openPaymentClearWarning}
          onExit={() => setOpenPaymentClearWarning(false)}
          onConfirm={() => {
            onClose();
            formik.setFieldValue('payments', []);
            setOpenPaymentClearWarning(false);
          }}
          confirmButtonText="exit"
          exitButtonText={t('cancel')}
          content={(
            <p>
              {t('you_are_about_to_abort_the_current_payment_flow')}
              <br />
              {t('any_payments_will_not_be_saved')}
              <br />
              <br />
              <br />
            </p>
          )}
        />
      </BaseDrawer.Drawer>
      <CreateBeneficiary
        firstPartyFlow={isEMoneyDisabled()}
        open={createBeneficiaryOpen}
        draftBeneficiary={beneToUpdate}
        handleDrawerClose={() => {
          setRefetchBene(refetchBene + 1);
          setCreateBeneficiaryOpen(false);
        }}
        updateDrawer
        header={t('update_bene')}
        refetch={setRefetchBene}
      />
    </>
  );
};

export default CreatePaymentDrawer;
