import AlphaConnectAdvert from 'assets/AlphaConnectAdvert.png';

import { makeStyles } from '@alpha/ui-lib/theme';

const useStyles = makeStyles(({ palette }) => ({
  header: {
    '& > .left': {
      minWidth: '400px',
    },
    '& .stageText': {
      color: 'white',
      marginBottom: '16px',
      fontWeight: 100,
      '& .name': {
        color: palette.primary.main,
        fontWeight: 600,
      },
    },
  },
  container: {
  },
  linearProgress: {
    width: '100%',
    backgroundColor: 'rgba(255,255,255,0.2)',
  },
  alphaLogo: {
    width: '144px',
    height: '36px',
  },
  leftContent: {
    '& .alphaLogo': {
      width: '144px',
      height: '36px',
    },
  },
  alphaAdvert: {
    backgroundImage: `url(${AlphaConnectAdvert})`,
    width: '700px',
    height: 'calc(100vh - 64px)',
    minHeight: '715px',
    backgroundColor: 'black',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    borderRadius: '24px',
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundPosition: 'center',
  },
  '@media (min-width: 1920px)': {
    container: {
    },
    alphaAdvert: {
      width: '700px',
      maxHeight: 'calc(100vh - 148px)',
    },
  },
  '@media (max-width: 820px)': {
    alphaAdvert: {
      width: '100%',
      height: 'auto',
    },
    container: {
    },
  },
  '@media (max-width: 1024px) and (min-width: 768px)': {
    alphaAdvert: {
      width: '100%',
      height: 'auto',
    },
  },

  rightContent: {
  },
}), { name: 'OnboardingStyles' });

export default useStyles;
